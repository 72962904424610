import * as Sentry from '@sentry/nextjs';
import LogRocket from 'logrocket';
import { version } from './package.json';

let sentryUser = {};

try {
  if (localStorage.getItem('persist:root')) {
    const serialState = JSON.parse(localStorage.getItem('persist:root'));
    const { user } = serialState?.auth
      ? JSON.parse(serialState.auth)
      : {
          user: {},
        };
    if (user?.names) {
      sentryUser = {
        name: `${user?.names} ${user?.surnames}`,
        email: user?.email,
        _id: user?._id,
      };

      Sentry.setUser(sentryUser);
    }
  }

  const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

  if (['production', 'staging', 'develop'].includes(process.env.NEXT_PUBLIC_ENVIRONMENT)) {
    Sentry.init({
      dsn: SENTRY_DSN,
      tracesSampleRate: 1.0,
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
    });

    LogRocket.init(process.env.NEXT_PUBLIC_LOG_ROCKET, {
      release: version,
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
      network: {
        requestSanitizer: (request) => {
          if (request.url.toLowerCase().indexOf('/_next/static/chunks/polyfills') !== -1) {
            return null;
          }
          return request;
        },
      },
    });
    LogRocket.identify(sentryUser?._id ?? 'NULL', {
      ...sentryUser,
    });
    LogRocket.getSessionURL((sessionURL) => {
      Sentry.configureScope((scope) => {
        scope.setExtra('sessionURL', sessionURL);
      });
    });
  }
} catch (error) {
  console.error(error);
}
